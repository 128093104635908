import * as React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { cardHoverDark, cardHoverLight } from './ProjectCard.module.css';
import './cardStyle.css';

import { parseInt } from 'lodash';
import { GetProjectsPhotos } from './QueryProjectsImages';
import StackImagesManager from './StackImagesManager';
import ProjectsImageManager from './ProjectsImageManager';
import { ThemeContext } from './ThemeContext';

const isBrowser = () => typeof window !== "undefined"
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const CardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: left;
  box-shadow: ${(props) => (props.theme.name === 'dark' ? '3px 3px 14px 1px #fff' : ' 3px 3px 14px 1px #888;')};
  max-width: ${(props) => (props.screenwidth)};
  height: fit-content;
  min-height: 470px;
  margin: 15px;
  padding: 10px;
  background: ${(props) => (props.theme.name === 'dark' ? '#626262fc' : '#d1d5d8')};
  transition: all 0.5s ease;
`;

const ProjectDate = styled.span`
  position: absolute;
  color: white;
  right:2;
  right: 20px;
  top: ${(props) => (props.screenwidth)};
  font-weight: 600;
  text-shadow: 0px 2px 11px darkblue, 1px 1px 10px blue;
  font-size: 16px;
  `;

const CustomButton = styled.button`
  background-color: ${props => props['data-theme'] === 'dark' ? '#5FC6EF' : '#6E757D'};
  border-radius: 6px;
  font-weight: 400;
  color: ${props => props['data-theme'] === 'dark' ? 'black' : 'white'};
  font-size: 16px;
  border: 0;
  height: 41px;
  transition: all 0.3s ease;
  `;

export const ProjectCard = (props) => {
  const [windowSize, setWindowSize] = React.useState({
    width: 501,
    height: 501,
  });

  const btnRef = React.createRef();
  React.useEffect(() => {
    if (btnRef && btnRef.current) {
      let randomInterval = (Math.random() * 4 + 2) * 1000;

      var h = setInterval(async () => {
        if (btnRef && btnRef.current) {
          if (btnRef.current.getAttribute('data-theme') === 'dark' && !btnRef.current.classList.contains("HighlightBtnDark")) {
            btnRef.current.classList.add("HighlightBtnDark")
            btnRef.current.innerText = '✨ More details ✨';
          }
          if (btnRef.current.getAttribute('data-theme') !== 'dark' && !btnRef.current.classList.contains("HighlightBtnLight")) {
            btnRef.current.classList.add("HighlightBtnLight")
            btnRef.current.innerText = '👉 More details 👈';
          }

          var r = await setTimeout(() => {
            if (btnRef && btnRef.current) {
              btnRef.current.classList.remove("HighlightBtnDark");
              btnRef.current.classList.remove("HighlightBtnLight");
              btnRef.current.innerText = 'More details';
            }
            r = null;
          }, 400);
          h = null;
        }
      }, randomInterval);
    }
  }, [btnRef])

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    isBrowser() && window.addEventListener("resize", handleResize);
    handleResize();
    return () => isBrowser() && window.removeEventListener("resize", handleResize);
  }, []);

  // let scrnWidth = isBrowser() && window.innerWidth;

  const projectsImgData = GetProjectsPhotos(props.image_title);

  const rating = parseInt(props.rating);
  const isActive = props.isActive?.toLowerCase() === 'true' ? true : false;
  const stack = props.stack?.split(',');
  stack &&
    stack.forEach(function (l, i) {
      this[i] = this[i].trim().toLowerCase();
      !this[i] && this.splice(i, 1);
    }, stack);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <CardWrapper screenwidth={windowSize.width > 500 ? '238px' : '293px'} theme={theme} className={theme.name === 'dark' ? cardHoverDark : cardHoverLight}>
          {rating === 5 && (
            <div className="ribbon gold">
              <span>SPECIAL !</span>
            </div>
          )}
          {isActive && (
            <div className="ribbon2 new">
              <span>ACTIVE</span>
            </div>
          )}

          {theme.name === 'dark' ? ProjectsImageManager(projectsImgData, props.image_title) : (['shaylavi', 'stickyar'].includes(props.image_title.toLowerCase()) ? ProjectsImageManager(projectsImgData, `${props.image_title}2`) : ProjectsImageManager(projectsImgData, props.image_title))}
          <div className="card-body bodyWrapper">
            <h5 className="card-title">{props.title}</h5>
            <ProjectDate screenwidth={windowSize.width > 500 ? '123px' : '157px'} className="project-date">{`${monthNames[props.date.getMonth()]} ${props.date.getFullYear()}`}</ProjectDate>
            <p className="card-text">{props.subtitle}</p>
          </div>
          <div className="btnWrapper">
            <div className="stack-wrapper">
              {stack.map((item) => {
                return StackImagesManager(item, theme);
              })}
            </div>
            <CustomButton
              data-theme={theme.name}
              onClick={props.clickHandler}
              id={props.id}
              ref={btnRef}
            >
              More details
            </CustomButton>
          </div>
        </CardWrapper>
      )}
    </ThemeContext.Consumer>
  );
};
